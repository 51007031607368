import { computed, ref, SetupContext } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { getToken, onMessage } from "firebase/messaging";
import { tryOnMounted, createSharedComposable } from "@vueuse/core";

export default createSharedComposable(function useNotifications({
  root,
}: Partial<SetupContext>) {
  const axiosInstance = computed(
    () => root?.$store.getters["api/getInstance"] as AxiosInstance
  );

  const getSwRegistration = () =>
    navigator.serviceWorker.getRegistration("/firebase");

  const watching = ref(false);

  const startWatching = () => {
    if (!watching.value) {
      const messaging = root?.$fcm;
      if (messaging) {
        watching.value = true;
        onMessage(messaging, (payload) => {
          console.log("Received foreground message ", payload);

          const { data } = payload;

          const timestamp =
            data && data["google.c.a.ts"]
              ? parseInt(data["google.c.a.ts"], 10)
              : new Date().getDate();

          const tag = data?.chatId ?? undefined;
          const count = data?.total ? parseInt(data.total, 10) : 1;
          const badge = "/img/icons/android-chrome-192x192.png";
          const body = data?.body ?? "Sprawdź aplikację CleverFleet";
          const icon = data?.image ?? badge ?? undefined;
          const dir = "ltr";
          const lang = "PL";
          const renotify = true;
          const requireInteraction = true;

          const notificationTitle = data?.title ?? "Powiadomienie";

          const notificationOptions: NotificationOptions = {
            body,
            icon,
            badge,
            timestamp,
            tag,
            dir,
            lang,
            data,
            renotify,
            requireInteraction,
          };

          const notification = new Notification(
            notificationTitle,
            notificationOptions
          );

          notification.addEventListener("click", async (event: any) => {
            event.preventDefault();
            notification.close();

            const { data } = notification;

            let url = "/";

            if (data?.type === "contact") {
              const id = data?.chatId;
              url = id ? `/contact/${id}` : "/contacts";
            }

            root?.$router.push(url);
          });

          if ("setAppBadge" in navigator)
            (navigator as Navigator & {
              setAppBadge: (count: number) => Promise<any>;
            })
              .setAppBadge(count > 0 ? count : 1)
              .then(() => [])
              .catch(() => []);
        });
      }
    }
  };

  tryOnMounted(startWatching);

  const registerDevice = (token: string) => {
    axiosInstance.value
      .post(`notification/token/${token}`)
      .then(() => {
        console.log("Registered device for notifications");
      })
      .catch(() => {
        console.log("Cannot register device for notifications");
      });
  };

  const ensureToken = () => {
    const messaging = root?.$fcm;
    if (messaging) {
      getSwRegistration().then((registration) => {
        getToken(messaging, {
          vapidKey:
            "BLBWLOtwC17xG_ydaYFFK4L7Dt5Dt7fAbwAcdirVHv3usnKek7oIAQE1M_Mpv_Ct0nXdn5-_QrCmiZ8IJVK2WkE",
          serviceWorkerRegistration: registration,
        })
          .then((currentToken) => {
            if (currentToken) {
              startWatching();
              console.log("Client token", currentToken);
              registerDevice(currentToken);
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      });
    }
  };

  const ensurePermissions = () => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        ensureToken();
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            ensureToken();
          } else {
            ensurePermissions();
          }
        });
      }
    } else {
      console.log("This device does not support notifications");
    }
  };

  return { ensurePermissions, ensureToken, registerDevice };
});
