import { State } from '@/store/state'
import { GetterTree } from 'vuex'
import { UserState } from './state'

export default {
  isLoggedIn: state => state.auth.token ? true : false,
  getToken: state => state.auth.token || false,
  getData: state => state.data || false,
  getCompanies: state => state.companies || false,
  hasAccessTo: state => (role: string) => {
    const roles = ['driver', 'customer', 'partner', 'employee', 'admin']
    return roles.indexOf(state.data ? state.data.role : 'guest') >= roles.indexOf(role)
  },
  hasManyCompanies: state => () => !!state.data?.companies?.length && state.data.companies.length > 1,
  getTosDialog: state => state.showTosDialog
} as GetterTree<UserState, State>
