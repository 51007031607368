import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'chats',
    name: 'panel.chat.list',
    component: () => import(/* webpackChunkName: "chat.list" */ '@/views/Panel/Chat/List.vue')
  },
  {
    path: 'chat/add',
    name: 'panel.chat.add',
    component: () => import(/* webpackChunkName: "chat.add" */ '@/views/Panel/Chat/Add.vue')
  },
  {
    path: 'chat/:id',
    name: 'panel.chat.view',
    component: () => import(/* webpackChunkName: "chat.view" */ '@/views/Panel/Chat/View.vue')
  }
] as RouteConfig[]