
export interface ContactState {
  unreadFetchTimer?: any;
  unread: {
    current: number;
    other: number;
  };
}

export const state: ContactState = {
  unread: {
    current: 0,
    other: 0
  }
}
