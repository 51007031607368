import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'report',
    name: 'panel.report.view',
    component: () => import(/* webpackChunkName: "report.list" */ '@/views/Panel/Report/View.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
] as RouteConfig[]
