export interface AuthData { token?: string }

export interface UserData {
  role: 'driver' | 'customer' | 'partner' | 'employee' | 'admin';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  companies: any[];
}

export interface UserCompany {
  id: string;
  name: string;
  description: string;
  logo: string;
  website: string;
  headquarters: { cist: string; country: string };
  industry: string;
  foundedYear: string;
}

export interface UserState {
  auth: AuthData;
  data?: UserData;
  companies?: UserCompany[];
  showTosDialog?: boolean;
}

export const state: UserState = {
  auth: { token: undefined },
  data: undefined,
  companies: [],
  showTosDialog: false,
}
