import Vue from 'vue'
import Vuex from 'vuex'

import { state, State } from './state'
import modules from './modules'
import getters from './getters'
import plugins from './plugins'

Vue.use(Vuex)

const store = new Vuex.Store<State>({ state, modules, getters, plugins })

export default store
