// Import the functions you need from the SDKs you need
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";

import { Messaging, getMessaging } from "firebase/messaging";
import { Analytics, getAnalytics } from "firebase/analytics";

import {
  AppCheck,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "firebase/app-check";

import _Vue from "vue";
import _ from "lodash";

export function FirebasePlugin(
  Vue: typeof _Vue,
  options: FirebaseOptions = {}
): void {
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyDqy5sHuUoy7tgESdDVZKYahTCd9jZuh2A",
    authDomain: "cleverfleet-460eb.firebaseapp.com",
    projectId: "cleverfleet-460eb",
    storageBucket: "cleverfleet-460eb.appspot.com",
    messagingSenderId: "393282037742",
    appId: "1:393282037742:web:5381f4f9e2974e0c7e50ff",
    measurementId: "G-4MXWY55HYB",
  };

  // Initialize Firebase
  const app = initializeApp(
    _.merge<FirebaseOptions, FirebaseOptions>(firebaseConfig, options)
  );

  const analytics = getAnalytics(app);
  const messaging = getMessaging(app);

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      "6Ldaz5MjAAAAAJrxbsT19KXB6Rv1oXsyp-AfmKjj"
    ),
    isTokenAutoRefreshEnabled: true,
  });

  Vue.prototype.$firebase = app;
  Vue.prototype.$analytics = analytics;
  Vue.prototype.$fcm = messaging;
  Vue.prototype.$appCheck = appCheck;
}

declare module "vue/types/vue" {
  interface Vue {
    $firebase: FirebaseApp;
    $analytics: Analytics;
    $fcm: Messaging;
    $appCheck: AppCheck;
  }
}

_Vue.use(FirebasePlugin);
