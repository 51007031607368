import store from "@/store";
import { RouteConfig } from "vue-router";

import dashboard from "./dashboard";
import vehicle from "./vehicle";
import user from "./user";
import alert from "./alert";
import request from "./request";
import chat from "./chat";
import company from "./company";
import contact from "./contact";
import settings from "./settings";
import report from "./report";
import insurance from "./insurance";
import leasing from "./leasing";
import service from "./service";
import fuelCardTransaction from "./fuel-card-transaction";
import inventory from "./inventory";
import fleetPolicy from "./fleet-policy";
import companyStructure from "./company-structure";
import damage from "./damage";

export default {
  path: "/",
  name: "panel",
  component: () => import(/* webpackChunkName: "panel" */ "@/views/Panel.vue"),
  redirect: { name: "panel.dashboard" },
  beforeEnter(to, from, next) {
    if (!store.getters["user/isLoggedIn"])
      next({ name: "auth", query: { next: to.fullPath } });
    next();
  },
  children: [
    ...dashboard,
    ...vehicle,
    ...user,
    ...alert,
    ...request,
    ...chat,
    ...company,
    ...contact,
    ...report,
    ...settings,
    ...insurance,
    ...service,
    ...leasing,
    ...fuelCardTransaction,
    ...inventory,
    ...fleetPolicy,
    ...companyStructure,
    ...damage,
    {
      path: "*",
      name: "error404",
      component: () => import("@/views/Panel/Error.vue"),
    },
  ],
} as RouteConfig;
