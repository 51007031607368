import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'alerts',
    name: 'panel.alert.list',
    component: () => import(/* webpackChunkName: "alert.list" */ '@/views/Panel/Alert/List.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'alert/:id',
    name: 'panel.alert.view',
    component: () => import(/* webpackChunkName: "alert.view" */ '@/views/Panel/Alert/View.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  }
] as RouteConfig[]
