import Qs from "qs";
import { State } from "@/store/state";
import { GetterTree } from "vuex";
import { ApiState } from "./state";

import axios from "axios";

export default {
  baseURL: (state) => state.baseURL,
  getInstance: (state, getters, rootState, rootGetters) =>
    axios.create({
      baseURL: state.baseURL,
      headers: { Authorization: `Bearer ${rootGetters["user/getToken"]}` },
      paramsSerializer: (params) =>
        Qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false,
        }),
    }),
} as GetterTree<ApiState, State>;
