import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import auth from './auth'
// import error from './error'
import panel from './panel'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [auth, panel/*, error*/]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('user/update').catch(() => next({ name: 'auth', query: { next: to.fullPath, error: 'update' } }))
  next()
})

export default router
