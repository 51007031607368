





















import { defineComponent, reactive } from '@vue/composition-api'
import { useEventListener } from '@vueuse/core'
import useNotifications from './use/notifications'
import update from './mixins/update'

export default defineComponent({
  mixins: [update],

  setup(_, { root }) {
    const { ensurePermissions } = useNotifications({ root })

    const state = reactive({
      loading: false,
      updateValue: 0
    })

    const downloadUpdate = () => new Promise<void>(resolve => {
      state.loading = true
      state.updateValue = 0

      setTimeout(() => {
        state.updateValue = 25
      }, 250)

      setTimeout(() => {
        state.updateValue = 50
      }, 500)

      setTimeout(() => {
        state.updateValue = 75
      }, 750)

      setTimeout(() => {
        state.updateValue = 100
        resolve()
      }, 1000)
    })

    const updateFirebase = () => {
      ensurePermissions()
    }

    useEventListener(document, 'firebase-registered', updateFirebase)
    useEventListener(document, 'firebase-updated', updateFirebase)


    return { state, downloadUpdate }
  }
})
