import { State } from '@/store/state'
import { GetterTree } from 'vuex'
import { ContactState } from './state'

export default {
  getUnreadCurrent: state => state.unread.current,
  getUnreadOther: state => state.unread.other,
  getUnreadTotal: state => state.unread.current + state.unread.other,

  hasUnreadCurrent: (_, getters) => getters.getUnreadCurrent > 0,
  hasUnreadOther: (_, getters) => getters.getUnreadOther > 0,
  hasUnreadTotal: (_, getters) => getters.getUnreadTotal > 0,
} as GetterTree<ContactState, State>