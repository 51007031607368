import { State } from "@/store/state";
import { AxiosInstance } from "axios";
import { ActionTree } from "vuex";
import { UserState } from "./state";

export default {
  login: ({ rootGetters, commit }, data: { email: string; password: string }) =>
    new Promise<void>((resolve, reject) => {
      const axiosInstance = rootGetters["api/getInstance"] as AxiosInstance;

      axiosInstance
        .post("auth/login", data)
        .then(({ data }) => {
          commit("SET_TOKEN", data.token);
          commit("SET_DATA", data.user);

          if (!data.user.acceptedTerms) commit("SET_TOS", true);
          else commit("SET_TOS", false);

          resolve();
        })
        .catch(() => {
          commit("REMOVE_TOKEN");
          commit("REMOVE_DATA");
          commit("SET_TOS", false);
          reject();
        });
    }),

  logout: ({ commit }) =>
    new Promise<void>((resolve) => {
      resolve();
      commit("REMOVE_TOKEN");
      commit("REMOVE_DATA");
      commit("REMOVE_COMPANY");
      commit("SET_TOS", false);
    }),

  update: ({ getters, rootGetters, commit }) =>
    new Promise<void>((resolve, reject) => {
      if (getters["isLoggedIn"]) {
        const axiosInstance = rootGetters["api/getInstance"] as AxiosInstance;

        axiosInstance
          .get("user/current")
          .then(({ data }) => {
            commit("SET_DATA", data.user);

            if (!data.user.acceptedTerms) commit("SET_TOS", true);
            else commit("SET_TOS", false);

            resolve();
          })
          .catch(() => {
            commit("REMOVE_TOKEN");
            commit("REMOVE_DATA");
            commit("SET_TOS", false);
            reject();
          });
      }
    }),

  updateCompany: ({ getters, rootGetters, commit }) =>
    new Promise<void>((resolve, reject) => {
      if (getters["isLoggedIn"]) {
        const axiosInstance = rootGetters["api/getInstance"] as AxiosInstance;

        axiosInstance
          .get("company/current")
          .then(({ data }) => {
            commit("SET_COMPANY", data);
            resolve();
          })
          .catch(() => {
            commit("REMOVE_COMPANY");
            reject();
          });
      }
    }),
} as ActionTree<UserState, State>;
