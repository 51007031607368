import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'fleet-policies',
    name: 'panel.fleet-policy.list',
    component: () => import(/* webpackChunkName: "fleet-policy.list" */ '@/views/Panel/FleetPolicy/List.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'fleet-policy/add',
    name: 'panel.fleet-policy.add',
    component: () => import(/* webpackChunkName: "fleet-policy.add" */ '@/views/Panel/FleetPolicy/Add.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('employee')) ? next() : next('/')
  },
  {
    path: 'fleet-policy/:id',
    name: 'panel.fleet-policy.view',
    component: () => import(/* webpackChunkName: "fleet-policy.view" */ '@/views/Panel/FleetPolicy/View.vue'),
    // beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
    beforeEnter: (to, from, next) => next('/')
  },
  {
    path: 'fleet-policy/:id/edit',
    name: 'panel.fleet-policy.edit',
    component: () => import(/* webpackChunkName: "fleet-policy.edit" */ '@/views/Panel/FleetPolicy/Edit.vue'),
    // beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('employee')) ? next() : next('/')
    beforeEnter: (to, from, next) => next('/')
  }
] as RouteConfig[]