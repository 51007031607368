import { State } from '@/store/state'
import { AxiosInstance } from 'axios'
import { ActionTree } from 'vuex'
import { CompanyModeState } from './state'

export default {
  setCompany: ({ commit, rootGetters }, id: string) => {
    const $axios = rootGetters['api/getInstance'] as AxiosInstance

    $axios
      .get(`company/${id}`)
      .then(({ data }) => {
        commit('SET_COMPANY', id)
        commit('SET_COMPANY_DATA', data)
      })
      .catch(() => {
        commit('REMOVE_COMPANY')
        commit('REMOVE_COMPANY_DATA')

        alert('Nie udało się przejść do trybu firmy')
      })
  },
  updateCompany: ({ commit, getters, rootGetters }) => {
    if (getters['isCompanyModeEndabled']) {
      const $axios = rootGetters['api/getInstance'] as AxiosInstance

      $axios
        .get(`company/${getters['getCompanyId']}`)
        .then(({ data }) => {
          commit('SET_COMPANY', getters['getCompanyId'])
          commit('SET_COMPANY_DATA', data)
        })
        .catch(() => {
          commit('REMOVE_COMPANY')
          commit('REMOVE_COMPANY_DATA')

          alert('Nie udało się pobrać danych firmy dla trybu firmy')
        })
      }
  },
  removeCompany: ({ commit }) => {
    commit('REMOVE_COMPANY')
    commit('REMOVE_COMPANY_DATA')
  }
} as ActionTree<CompanyModeState, State>
