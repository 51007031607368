import { Plugin } from 'vuex'
import { State } from './state'

import createPersistedState from 'vuex-persistedstate'

export default [
  createPersistedState({
    key: 'cleverfleet_user',
    paths: ['user.data', 'user.auth'],
    storage: window.localStorage
  }),
  createPersistedState({
    key: 'cleverfleet_companyMode',
    paths: ['companyMode.company', 'companyMode.companyData'],
    storage: window.localStorage
  })
] as Plugin<State>[]