import store from "@/store";
import { RouteConfig } from "vue-router";

export default [
  {
    path: "damages",
    name: "panel.damage.list",
    component: () =>
      import(
        /* webpackChunkName: "damage.list" */ "@/views/Panel/Damage/List.vue"
      ),
    beforeEnter: (to, from, next) =>
      store.getters["user/hasAccessTo"]("customer") ? next() : next("/"),
  },
] as RouteConfig[];
