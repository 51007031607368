import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'company-structures',
    name: 'panel.company-structure.list',
    component: () => import(/* webpackChunkName: "company-structure.list" */ '@/views/Panel/CompanyStructure/List.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'company-structure/add',
    name: 'panel.company-structure.add',
    component: () => import(/* webpackChunkName: "company-structure.add" */ '@/views/Panel/CompanyStructure/Add.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'company-structure/:id',
    name: 'panel.company-structure.view',
    component: () => import(/* webpackChunkName: "company-structure.view" */ '@/views/Panel/CompanyStructure/View.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'company-structure/:id/edit',
    name: 'panel.company-structure.edit',
    component: () => import(/* webpackChunkName: "company-structure.edit" */ '@/views/Panel/CompanyStructure/Edit.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  }
] as RouteConfig[]