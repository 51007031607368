import { MutationTree } from 'vuex'
import { UserCompany, UserData, UserState } from './state'

export default {
  SET_TOKEN: (state, token: string) => state.auth.token = token,
  REMOVE_TOKEN: state => state.auth.token = undefined,
  SET_DATA: (state, data: UserData) => state.data = data,
  REMOVE_DATA: state => state.data = undefined,
  SET_COMPANY: (state, data: UserCompany[]) => state.companies = data,
  REMOVE_COMPANY: state => state.companies = undefined,
  SET_TOS: (state, value: boolean) => state.showTosDialog = value,
} as MutationTree<UserState>
