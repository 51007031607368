import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'users',
    name: 'panel.user.list',
    component: () => import(/* webpackChunkName: "user.list" */ '@/views/Panel/User/List.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'user/add',
    name: 'panel.user.add',
    component: () => import(/* webpackChunkName: "user.add" */ '@/views/Panel/User/Add.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'user/:id/edit',
    name: 'panel.user.edit',
    component: () => import(/* webpackChunkName: "user.edit" */ '@/views/Panel/User/Edit.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('admin')) ? next() : next('/')
  },
  {
    path: 'user/:id',
    name: 'panel.user.view',
    component: () => import(/* webpackChunkName: "user.view" */ '@/views/Panel/User/View.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('employee')) ? next() : next('/')
  }
] as RouteConfig[]