import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'fuel-card-transactions',
    name: 'panel.fuel-card-transaction.list',
    component: () => import(/* webpackChunkName: "fuel-card-transaction.list" */ '@/views/Panel/FuelCardTransaction/List.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'fuel-card-transaction/add',
    name: 'panel.fuel-card-transaction.add',
    component: () => import(/* webpackChunkName: "fuel-card-transaction.add" */ '@/views/Panel/FuelCardTransaction/Add.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'fuel-card-transaction/:id',
    name: 'panel.fuel-card-transaction.view',
    component: () => import(/* webpackChunkName: "fuel-card-transaction.view" */ '@/views/Panel/FuelCardTransaction/View.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  }
] as RouteConfig[]
