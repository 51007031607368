
export interface CompanyModeState {
  company: string | null;
  companyData: { [key: string]: any } | null;
}

export const state: CompanyModeState = {
  company: null,
  companyData: null
}
