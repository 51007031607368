import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'requests',
    name: 'panel.request.list',
    component: () => import(/* webpackChunkName: "request.list" */ '@/views/Panel/Request/List.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'requests/all',
    name: 'panel.request.list-all',
    component: () => import(/* webpackChunkName: "request.list-all" */ '@/views/Panel/Request/ListAll.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('employee')) ? next() : next('/')
  },
  {
    path: 'request/add',
    name: 'panel.request.add',
    component: () => import(/* webpackChunkName: "request.add" */ '@/views/Panel/Request/Add.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'request/:id',
    name: 'panel.request.view',
    component: () => import(/* webpackChunkName: "request.view" */ '@/views/Panel/Request/View.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  }
] as RouteConfig[]