import store from "@/store";
import { RouteConfig } from "vue-router";

export default [
  {
    path: "contacts",
    name: "panel.contact.list",
    component: () =>
      import(
        /* webpackChunkName: "contact.list" */ "@/views/Panel/Contact/List.vue"
      ),
    beforeEnter: (to, from, next) =>
      store.getters["user/hasAccessTo"]("customer") ? next() : next("/"),
  },
  {
    path: "contact",
    name: "panel.contact.user",
    component: () =>
      import(
        /* webpackChunkName: "contact.user" */ "@/views/Panel/Contact/User.vue"
      ),
  },
  {
    path: "contact/add",
    name: "panel.contact.add",
    component: () =>
      import(
        /* webpackChunkName: "contact.add" */ "@/views/Panel/Contact/Add.vue"
      ),
    beforeEnter: (to, from, next) =>
      store.getters["user/hasAccessTo"]("customer") ? next() : next("/"),
  },
  {
    path: "contact/:id",
    name: "panel.contact.service",
    component: () =>
      import(
        /* webpackChunkName: "contact.service" */ "@/views/Panel/Contact/Admin.vue"
      ),
    beforeEnter: (to, from, next) =>
      store.getters["user/hasAccessTo"]("customer") ? next() : next("/contact"),
  },
] as RouteConfig[];
