import { MutationTree } from 'vuex'
import { ContactState } from './state'

export default {
  SET_UNREAD_CURRENT: (state, count: number) => state.unread.current = count,
  SET_UNREAD_OTHER: (state, count: number) => state.unread.other = count,

  REMOVE_UNREAD_CURRENT: state => state.unread.current = 0,
  REMOVE_UNREAD_OTHER: state => state.unread.other = 0,

  SET_TIMER: (state, timer: number) => state.unreadFetchTimer = timer,
  REVOME_TIMER: (state) => {
    if (state.unreadFetchTimer) {
      clearInterval(state.unreadFetchTimer)
      state.unreadFetchTimer = undefined
    }
  }
} as MutationTree<ContactState>
