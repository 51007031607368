import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'vehicles',
    name: 'panel.vehicle.list',
    component: () => import(/* webpackChunkName: "vehicle.list" */ '@/views/Panel/Vehicle/List.vue')
  },
  {
    path: 'vehicle/add',
    name: 'panel.vehicle.add',
    component: () => import(/* webpackChunkName: "vehicle.add" */ '@/views/Panel/Vehicle/Add.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  },
  {
    path: 'vehicle/:id',
    name: 'panel.vehicle.view',
    component: () => import(/* webpackChunkName: "vehicle.view" */ '@/views/Panel/Vehicle/View.vue')
  },
  {
    path: 'vehicle/:id/edit',
    name: 'panel.vehicle.edit',
    component: () => import(/* webpackChunkName: "vehicle.edit" */ '@/views/Panel/Vehicle/Edit.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  }
] as RouteConfig[]