import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'insurance/:id',
    name: 'panel.insurance.view',
    component: () => import(/* webpackChunkName: "insurance.view" */ '@/views/Panel/Insurance/View.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  }
] as RouteConfig[]
