import { State } from '@/store/state'
import { AxiosInstance, AxiosResponse } from 'axios'
import { ActionTree } from 'vuex'
import { ContactState } from './state'


function setBadge(...args: any) {
  if ((navigator as any).setAppBadge) {
    (navigator as any).setAppBadge(...args);
  } else if ((navigator as any).setExperimentalAppBadge) {
    (navigator as any).setExperimentalAppBadge(...args);
  } else if ((window as any).ExperimentalBadge) {
    (window as any).ExperimentalBadge.set(...args);
  }
}

function clearBadge() {
  if ((navigator as any).clearAppBadge) {
    (navigator as any).clearAppBadge();
  } else if ((navigator as any).clearExperimentalAppBadge) {
    (navigator as any).clearExperimentalAppBadge();
  } else if ((window as any).ExperimentalBadge) {
    (window as any).ExperimentalBadge.clear();
  }
}

export default {
  fetchUnread: ({ rootGetters, getters, commit }) => new Promise<void>((resolve, reject) => {
    const axiosInstance = (rootGetters['api/getInstance'] as AxiosInstance)

    const fetchUnreadOther = new Promise<void>((resolve, reject) =>
      axiosInstance
        .get('contact/unread')
        .then(({ data }) => {
          commit('SET_UNREAD_OTHER', data.count)
          resolve()
        })
        .catch(() => {
          commit('REMOVE_UNREAD_OTHER')
          reject()
        })
    )

      const fetchUnreadCurrent = new Promise<void>((resolve, reject) =>
        axiosInstance
          .get('contact/current/unread')
          .then(({ data }) => {
            commit('SET_UNREAD_CURRENT', data.count)
            resolve()
          })
          .catch(() => {
            commit('REMOVE_UNREAD_CURRENT')
            reject()
          })
      )

      return Promise
        .any([fetchUnreadOther, fetchUnreadCurrent])
        .then(resolve)
        .catch(reject)
        .finally(() => {
          if (getters['hasUnreadTotal']) setBadge(getters['getUnreadTotal'])
          else clearBadge()
        })
  }),

  fetchUnreadFrom: ({ rootGetters }, id: string) => new Promise<AxiosResponse>((resolve, reject) => {
    const axiosInstance = (rootGetters['api/getInstance'] as AxiosInstance)

    axiosInstance
      .get(`contact/${id}/unread`)
      .then(resolve)
      .catch(reject)
  }),

  startTimer: ({ commit, dispatch }) => {
    dispatch('fetchUnread').catch(console.log)

    commit('SET_TIMER', setInterval(() => {
      dispatch('fetchUnread').catch(console.log)
    }, 5000))
  },

  stopTimer: ({ commit }) => {
    commit('REVOME_TIMER')
  }
} as ActionTree<ContactState, State>
