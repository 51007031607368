import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'inventories',
    name: 'panel.inventory.list',
    component: () => import(/* webpackChunkName: "inventory.list" */ '@/views/Panel/Inventory/List.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('customer')) ? next() : next('/')
  }
] as RouteConfig[]
