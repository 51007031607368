import store from '@/store';
import { RouteConfig } from 'vue-router';

export default [
  {
    path: 'companies',
    name: 'panel.company.list',
    component: () => import(/* webpackChunkName: "company.list" */ '@/views/Panel/Company/List.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('employee') || store.getters['user/hasManyCompanies']()) ? next() : next('/')
  },
  {
    path: 'company/add',
    name: 'panel.company.add',
    component: () => import(/* webpackChunkName: "company.add" */ '@/views/Panel/Company/Add.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('employee')) ? next() : next('/')
  },
  {
    path: 'company/:id/edit',
    name: 'panel.company.edit',
    component: () => import(/* webpackChunkName: "company.edit" */ '@/views/Panel/Company/Edit.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('employee')) ? next() : next('/')
  },
  {
    path: 'company/:id',
    name: 'panel.company.view',
    component: () => import(/* webpackChunkName: "company.view" */ '@/views/Panel/Company/View.vue'),
    beforeEnter: (to, from, next) => (store.getters['user/hasAccessTo']('employee')) ? next() : next('/')
  }
] as RouteConfig[]
